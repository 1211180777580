<template>
  <div>
    <AlbumAlfa
      v-if="openFolder == 'Alfa'"
      v-on:handleZoom="handleZoom"
    />
  </div>
</template>

<script>
import AlbumAlfa from './album/T-A-Alfa.vue'

export default {
  name: 'Template-Mini-Foto',
  props: ['openFolder'],
  data () {
    return {}
  },
  components: {
    AlbumAlfa
  },
  methods: {
    handleZoom (data) {
      this.$emit('handleZoom', data)
    }
  }
}
</script>
