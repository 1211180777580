<template>
  <div class="row h-100vh m-0 d-flex justify-content-center align-items-center overflow scrollbar-none">
    <TFoto/>
  </div>
</template>

<script>
import TFoto from '../../components/template/foto/T-Foto.vue'

export default {
  name: 'Foto',
  data () {
    return {
    }
  },
  components: {
    TFoto
  },
  methods: {
  }
}
</script>
