<template>
  <div class="d-flex p-1 bg-danger justify-content-between">
    <button class="w-auto" @click.prevent="goHome">BACK</button>
    <div><b>{{ title }}</b></div>
    <div class="opacity-25">SELVIN</div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['title'],
  data () {
    return {}
  },
  methods: {
    goHome () {
      this.$router.replace({
        name: 'Home'
      })
    }
  }
}
</script>
