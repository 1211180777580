<template>
  <div class="row h-100vh m-0 p-0 d-flex justify-content-center align-items-center overflow scrollbar-none">
    <div v-for="item in items" :key="item" class="col">
      <IconFolder v-on:selectItem="selectItem(item)"/>
      <NameFolder :nameFolder="item"/>
    </div>
  </div>
</template>

<script>
import IconFolder from './F-Icon.vue'
import NameFolder from './F-Name.vue'

export default {
  name: 'Item-Foto',
  props: ['items'],
  data () {
    return {}
  },
  components: {
    IconFolder,
    NameFolder
  },
  methods: {
    selectItem (item) {
      this.$emit('selectItem', item)
    }
  }
}
</script>
