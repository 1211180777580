<template>
  <div class="row h-100vh m-0 p-0 d-flex justify-content-center align-items-center overflow scrollbar-none">
    <div v-for="image in images" :key="image" class="col">
      <MiniFoto
        :image="image"
        v-on:handleZoom="handleZoom"
        class="m-1"
      />
    </div>
  </div>
</template>

<script>
import MiniFoto from '../../../card/foto/mini.vue'
import F17 from '../../../../assets/foto/17.jpg'
import F16 from '../../../../assets/foto/16.jpg'
import F15 from '../../../../assets/foto/15.jpg'
import F14 from '../../../../assets/foto/14.jpg'
import F13 from '../../../../assets/foto/13.jpg'
import F12 from '../../../../assets/foto/12.jpg'
import F11 from '../../../../assets/foto/11.jpg'
import F10 from '../../../../assets/foto/10.jpg'
import F9 from '../../../../assets/foto/9.jpg'
import F8 from '../../../../assets/foto/8.jpg'
import F7 from '../../../../assets/foto/7.jpg'
import F6 from '../../../../assets/foto/6.jpg'
import F5 from '../../../../assets/foto/5.jpg'
import F4 from '../../../../assets/foto/4.jpg'
import F3 from '../../../../assets/foto/3.jpg'
import F2 from '../../../../assets/foto/2.jpg'
import F1 from '../../../../assets/foto/1.jpg'

export default {
  name: 'Template-Mini-Foto',
  data () {
    return {
      images: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F13, F14, F15, F16, F17]
    }
  },
  components: {
    MiniFoto
  },
  methods: {
    handleZoom (data) {
      this.$emit('handleZoom', data)
    }
  }
}
</script>
