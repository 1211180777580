<template>
  <div class="row h-100vh m-0 d-flex justify-content-center align-items-center">
    <ZoomFoto
      :image="image"
      v-on:handleClose="handleClose"
    />
  </div>
</template>

<script>
import ZoomFoto from '../../card/foto/zoom.vue'

export default {
  name: 'Template-Full-Frame',
  props: ['image'],
  data () {
    return {}
  },
  components: {
    ZoomFoto
  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>
