<template>
  <div class="row m-0 p-0 d-flex justify-content-center align-items-center bg-foto">
    <Navbar :title="'FOTO'"/>
    <Folder v-if="openFolder == ''" :items="locations" v-on:selectItem="selectItem"/>
    <TMiniFoto
      v-if="!isZoom && openFolder != ''"
      :openFolder="openFolder"
      :images="images"
      v-on:handleZoom="handleZoom"
    />
    <TFullFrame
      v-if="isZoom && openFolder != ''"
      :image="imZoom"
      v-on:handleClose="handleClose"
    />
  </div>
</template>

<script>
import Navbar from '../../base/Navbar.vue'
import Folder from '../../card/folder/F-Item.vue'
import TMiniFoto from './T-Mini-Foto.vue'
import TFullFrame from './T-Full-Frame.vue'

export default {
  name: 'Template-Foto',
  data () {
    return {
      locations: ['Alfa'],
      openFolder: '',
      isZoom: false,
      imZoom: null
    }
  },
  components: {
    Navbar,
    TMiniFoto,
    TFullFrame,
    Folder
  },
  methods: {
    selectItem (data) {
      this.openFolder = data
    },
    handleZoom (data) {
      this.isZoom = !this.isZoom
      this.imZoom = data
    },
    handleClose () {
      this.isZoom = !this.isZoom
    }
  }
}
</script>
