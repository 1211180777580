<template>
  <div>{{ nameFolder }}</div>
</template>

<script>
export default {
  name: 'Name-Folder',
  props: ['nameFolder'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
