<template>
  <div>
    <img
      :src="image"
      class="h-20vh w-auto c-pointer"
      @click.prevent="onClick"
    >
  </div>
</template>

<script>
export default {
  name: 'Mini-Foto',
  props: ['image'],
  data () {
    return {
    }
  },
  methods: {
    onClick () {
      this.$emit('handleZoom', this.image)
    }
  }
}
</script>
