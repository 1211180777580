<template>
  <div>
    <img
      src="../../../assets/folder.png"
      class="h-20vh w-auto c-pointer"
      @click="selectItem"
    >
  </div>
</template>

<script>
export default {
  name: 'Icon-Folder',
  data () {
    return {
    }
  },
  methods: {
    selectItem () {
      this.$emit('selectItem')
    }
  }
}
</script>
